body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=search]{
  -moz-appearance: none;/* older firefox */
  -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
  appearance: none; /* rest */
}

.App_container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
  background:#0091ea !important;
  border: #01579b !important;
}

.switch label input[type=checkbox]:checked+.lever:after {
  background:#c3bfff !important;
}

.switch label input[type=checkbox]:checked+.lever {
  background:#b3e5fc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.collapse {
  display: none;
}

.text-bold {
  font-weight: 700;
}

.text-italic {
  font-style: italic;
}

.material-tooltip {
  max-width: calc(40%) !important;
  text-align: left !important;
}

.material-icons.tooltipped {
  font-size: 15px;
}

div.collapsible-body {
  padding-top: 0px;
}

.row.collapsible-header {
  margin-bottom: 0px;
}

.row.flush {
  margin-bottom: 0px;
}

.collapsible-body h5 {
  margin: .5rem 0 1.5rem 0;
}

#add_investment {
  margin: 3rem 0 0 1rem;
}

nav {
  margin-bottom: 20px;
  box-shadow: none !important;
}

nav ul a {
  color: #37474f !important;
}

.nav-logo {
  width: 35px;
  margin-top: 8px;
  margin-right: 5px;
}

.login-display > span {
  font-size: 85px;
  vertical-align: middle;
}

.login-display {
  font-size: 30px;
}

.login-form-content {
  margin-top: 20px;
  margin-bottom: 100px;
  padding: 20px !important;
  border-radius: 25px;
}

.login-image {
  margin-bottom: 10px;
  margin-right: 5px;
  vertical-align: middle;
  width: 100px;
}

.login-footer > * {
  margin: 15px 0px 15px 0px;
}

.login-button {
  width: 100%;
}

.progress {
  height: 15px !important;
  margin: 0px !important;
}

#loading {
  min-height: 500px;
  vertical-align: middle;
  text-align: center;
}

#ws-walkscore-tile {
  position: relative;
  text-align: left;
}

#ws-walkscore-tile * {
  float: none;
}

.about-horizontal {
  padding: 60px 0 60px 0;
}

.about-horizontal p {
  margin: 50px 0 50px 0;
}

.roadmap .card {
  min-height: 200px !important;
}

.alpha-card {
  margin-top: -60px;
}

#profile_dropdown {
  top: -50px;
}

a > i.profile_dropdown_icon {
  margin-left: 0px;
}

.form-icon {
  font-size: 35px !important;
  padding-left: 30px;
}

.transparent-background {
  background: rgba(60, 60, 60, 0.6);
  padding: 5px;
}

.clickable {
  text-decoration: none;
  color: #fff;
  background: rgba(60, 60, 60, 0.75);
  transition: background-color .2s ease-out;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  -webkit-tap-highlight-color: transparent;
  border: none !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.clickable:hover {
  background: rgba(255, 141, 34, 0.9);
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%), 0 1px 7px 0 rgb(0 0 0 / 12%), 0 3px 1px -1px rgb(0 0 0 / 20%);
}

.clickable:focus {
  background: rgba(60, 60, 60, 0.75);
}


.clickable i {
  font-size: 20px !important;
}

.rounded {
  border-radius: 25px;
}

.card-title {
  font-weight: bold !important;
  padding: 10px !important;
  width: 100% !important;
  font-size: 16px !important;
}

.card-status {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  margin-top: 5px;
  padding-right: 10px;
  border-radius: 0px 10px 10px 0px;
}

.card-icons {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100%;
}

.card-content .col.s5 {
  text-align: right;
}

.card-content .col.s6 i {
  float: left;
  margin-right: 5px;
}

.card-content .col.s6 span {
  display: inline-block;
}

.card-content .col.s6 span::first-letter {
  text-transform: uppercase;
}

.card-content .col.s6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-content .col.s6:hover{
  overflow: visible; 
  white-space: normal;
  height:auto;
}

.card-icons button {
  margin: 5px 5px 0px 0px;
}

.card-content .row {
  margin-bottom: 12px;
}

.deal-icons {
  text-align: right;
}

.deal-icons button {
  margin: 5px 5px 0px 0px;
}

.deal-header {
  min-height: 100px;
  text-align: center;
  margin-bottom: 0px !important;
  border-radius: 25px 25px 0px 0px;
  background: #275E63 !important;
}

.deal-header-content {
  padding: 30px 0px 30px 0px;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
}

.deal-header .investment-price {
  font-size: 50px;
  font-family: 'DM Serif Display', serif;
  margin: 40px 40px 40px 40px;
  vertical-align: middle;
}

.deal-header button {
  width: 150px;
  background: rgba(255, 141, 34, 0.9);
}

.deal-highlights {
  margin-top: -20px;
  padding: 25px 0px 15px 0px;
  border-radius: 25px 25px 0px 0px;
  border-top: 5px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
  background-color: #BECFD0 !important;
}

.highlights-text {
  font-family: 'DM Sans', sans-serif;
}

.highlight {
  font-size: 30px;
  font-family: 'DM Serif Display', serif;
}

.highlight-image {
  margin-right: 15px;
  height: 50px;
}

.tabs {
  border: 1px solid rgb(228, 228, 228);
  border-radius: 5px;
}

.tabs .tab {
  text-transform: none !important;
}

.tabs .tab a {
  overflow: visible !important;
  color: black !important;

}

.tabs .tab a.active, .tabs .tab a:focus {
  background-color: rgb(228, 228, 228) !important;
  font-weight: bold;
}

.tabs .tab a:hover {
  background-color: rgb(228, 228, 228) !important;
}

.tabs .indicator {
  background-color: rgb(228, 228, 228) !important;
}


#beta-notice {
  position:fixed;
  bottom: 0px;
  left: 0px;
}

.tap-target-content {
  padding: 0px 160px 30px 40px !important;
}

#toast-container {
  top: auto !important;
  right: auto !important;
  bottom: 10%;
  left:7%;
  min-width: 300px !important;
}

.btn, .btn-large {
  text-transform: none !important;
}

.row .col.l3 {
  min-height: 14em;
} 

.output {
  margin: 10px 0px 20px 5px;
}

.output div.switch {
  margin-bottom: 5px;
}

.output .row {
  margin-bottom: 1px;
  border-radius: 2px;
}

.output h6 {
  margin-top: 0px !important;
}

.output .switch label .lever {
  vertical-align: initial;
}

.footer-terms {
  font-size: 12px;
  line-height: 1;
}

.base-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
  }

  .content {
    display: flex;
    flex-direction: column;

    .image {
      width: 21em;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .form {
      margin-top: 2em;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: fit-content;
        label {
          font-size: 20px;
        }
        input {
          margin-top: 6px;
          min-width: 18em;
          height: 37px;
          padding: 0px 10px;
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          background-color: #f3f3f3;
          border: 0;
          border-radius: 4px;
          margin-bottom: 31px;
          transition: all 250ms ease-in-out;
          &:hover {
            //background-color: #ffffff;
            //box-shadow: 0px 0px 14px 0.3px #0e81ce96;
          }

          &:focus {
            outline: none;
            box-shadow: 0px 0px 12px 0.8px #3474dbb2;
          }
        }
      }
    }
  }

  .footer {
    margin-top: 3em;
  }
}